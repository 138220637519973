// Stylesheets
import "./src/assets/css/revolutionSlider.css"
import "./src/assets/css/bootstrap.css"
import "./src/assets/css/colors/blue.css"
import "./src/assets/css/yamm.min.css"
import "./src/assets/css/slowave.css"
import "./src/assets/css/styles.css"
import "./src/assets/type/fontello.css"
import "./src/assets/css/ref-carousel.css"
import "./src/assets/css/globals.css"

export function onRouteUpdate({location}) {
  if(location.hash) {
    setTimeout(()=>{
      document.querySelector(`${location.hash}`).scrollIntoView()
    }, 0)
  }
}